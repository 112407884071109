<template>
    <div class="admin-content">
        <h3>그룹 사용자 관리</h3>

        <div class="flex-wrap right mb30">
            <div>
			    <div class="btn-wrap">
                    <button type="button" 
                    class="btn-box green"
                    @click="eventHandler.button.save.onClick">저장</button>
			    </div>
            </div>
		</div>

        <div class="group-chage-wrap">
            <div>
                <div class="flex-wrap half">
                    <div  class="col-5">

                        <!-- 그룹선택 -->
		    	    	<div class="table-title-wrap">
		    	    		<p class="a-tit2">그룹선택</p>
		    	    		<!-- 총 개수 -->
                            <div class="total-box">
		                	    <p>Total <strong>{{state.dataTable.group.groups === null ? 0 : state.dataTable.group.groups.length}}</strong></p>
		                    </div>
		    	    	</div>

                        <div class="table-wrap">
							<div class="table-box">
                                <DataTable class="p-datatable-sm"
                                    columnResizeMode="fit"
                                    scrollHeight="497px"
                                    selectionMode="single"
                                    v-model:selection="state.dataTable.group.selectedGroup"
                                    :value="state.dataTable.group.groups"
                                    :resizableColumns="true">

                                    <template #empty>
                                        데이터가 없습니다.
                                    </template>
                                
                                    <Column field="CodeVal" header="그룹ID" :sortable="true" style="width: 150px;" headerClass="tc"></Column>
                                    <Column field="CodeName" header="그룹명" :sortable="true" style="width: 150px;" headerClass="tc"></Column>
                                </DataTable>
                            </div>
                        </div>
                        <!-- //그룹선택 -->
                    </div>
                    <div class="col-7">

                        <!-- 등록된 사용자 -->
						<div class="table-title-wrap">
							<p class="a-tit2">등록된 사용자</p>
                            <!-- 총 개수 -->
							<div class="total-box">
		                	    <p>Total <strong>{{state.dataTable.registeredUser.users === null ? 0 : state.dataTable.registeredUser.users.length}}</strong></p>
		                    </div>
						</div>

                        <div class="table-wrap">
							<div class="table-box">
                                <DataTable class="p-datatable-sm"
                                    scrollHeight="497px"
                                    columnResizeMode="fit"
                                    selectionMode="multiple"
                                    v-model:selection="state.dataTable.registeredUser.selectedUsers"
                                    :value="state.dataTable.registeredUser.users"
                                    :metaKeySelection="false"
                                    :resizableColumns="true">
                                
                                    <template #empty>
                                        데이터가 없습니다.
                                    </template>
                                
                                    <Column selectionMode="multiple" style="text-align:center; width:50px;" headerClass="tc"></Column>
                                    <Column field="UserID" header="사용자ID" :sortable="true" style="width:150px;" headerClass="tc"></Column>
                                    <Column field="UserName" header="사용자명" :sortable="true" style="width:150px;" headerClass="tc"></Column>
                                    <Column field="Department" header="부서" :sortable="true" style="width:150px;" headerClass="tc"></Column>
                                </DataTable>
                            </div>
                        </div>
                        <!-- //등록된 사용자 -->
                    </div>
                </div>
            </div>
            <div class="group-chage-btn-box">
				<ul>
					<li><a @click="eventHandler.button.registerUser.onClick" title="좌측으로" class="left-btn"><span class="text_hidden">좌측으로</span></a></li>
					<li><a @click="eventHandler.button.unregisterUser.onClick" title="우측으로" class="right-btn"><span class="text_hidden">우측으로</span></a></li>
				</ul>
			</div>
            <div>
                <!-- 미등록 사용자 -->
				<div class="table-title-wrap">
					<p class="a-tit2">미등록 사용자</p>
                    <!-- 총 개수 -->
		            <div class="total-box">
		            	<p>Total <strong>{{state.dataTable.unregisteredUser.users === null ? 0 : state.dataTable.unregisteredUser.users.length}}</strong></p>
		            </div>
				</div>
				<div class="sch-wrap2 mb30">
					<div class="select-box">
                        <select v-model="state.dropdown.userAdType.selectedItem">
                            <option v-for="item in state.dropdown.userAdType.items" v-bind:value="item.value" v-bind:key="item" v-text="item.displayValue"  />
                        </select>
                    </div>
					<input type="text" class="input-text" placeholder="부서명/사용자명"
                        v-model="state.searchKeyword"
                        @keyup.enter="eventHandler.button.searchUnregisteredUser.onClick" />
					<button title="검색" class="submit-btn" @click="eventHandler.button.searchUnregisteredUser.onClick"><span class="text_hidden">검색</span></button>
				</div>
                <div class="table-wrap">
					    <div class="table-box">

                        <DataTable class="p-datatable-sm"
                            scrollHeight="497px"
                            selectionMode="multiple"
                            columnResizeMode="fit"
                            v-model:selection="state.dataTable.unregisteredUser.selectedUsers"
                            :value="state.dataTable.unregisteredUser.users"
                            :metaKeySelection="false"
                            :resizableColumns="true">
                        
                            <template #empty>
                                데이터가 없습니다.
                            </template>
                        
                            <Column selectionMode="multiple" style="text-align:center; width:50px;" headerClass="tc"></Column>
                            <Column field="UseADType" header="구분" :sortable="true" style="width:150px;" headerClass="tc"></Column>
                            <Column field="UserID" header="사용자ID" :sortable="true" style="width:150px;" headerClass="tc"></Column>
                            <Column field="UserName" header="사용자명" :sortable="true" style="width:150px;" headerClass="tc"></Column>
                            <Column field="Department" header="부서" :sortable="true" style="width:150px;" headerClass="tc"></Column>
                        </DataTable>
                    </div>
                </div>
                <!-- //미등록 사용자 -->
            </div>
        </div>
    </div>  
</template>
<script>
import { reactive } from '@vue/reactivity'
import { onMounted, watch } from '@vue/runtime-core';
import { groupUserManage } from '@/modules/site-training/group-user-manage.js';
import { useStore } from 'vuex';
import alertConfirmManager from '@/utils/alert-confirm-manager.js';
export default {
    components: { },
    setup() {
        
        // 스토어
        const store = useStore();

        // state
        const state = reactive({

            // 데이터 테이블 관련
            dataTable: {
                
                // 그룹
                group: {

                    // 그룹목록
                    groups: new Array(),

                    // 선택한 그룹
                    selectedGroup: null,
                },

                // 등록된 사용자
                registeredUser: {

                    // 사용자 목록
                    users: new Array(),

                    // 선택한 사용자 목록
                    selectedUsers: new Array(),
                },

                // 미등록된 사용자
                unregisteredUser: {

                    // 사용자 목록
                    users: new Array(),

                    // 선택한 사용자 목록
                    selectedUsers: new Array(),
                },
            },

            // 드롭다운 관련 (select box)
            dropdown: {

                // 사용자 AD 유형
                userAdType: {

                    // 아이템 목록
                    items: [
                        { value: null, displayValue: '전체' },
                        { value: 'AD', displayValue: 'AD계정' },
                        { value: 'DB', displayValue: '임시계정' },
                    ],

                    // 선택한 아이템 (default: 전체)
                    selectedItem: null,
                }
            },

            // 검색어
            searchKeyword: null,
        });

        // 메서드
        const methods = {

            // 그룹목록 가져오기
            getGroups: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                groupUserManage.getGroups().then(groups => {

                    // 그룹목록 설정
                    state.dataTable.group.groups = groups;
                }).finally(() => store.commit("setLoadingStatus", false));
            },

            // 그룹 사용자 목록 가져오기 (등록된, 미등록)
            getGroupUsers: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                // 등록된 사용자
                groupUserManage.getGroupRegisteredUsers(
                    state.dataTable.group.selectedGroup.CodeVal
                ).then(registeredUsers => {

                    // 등록된 사용자 목록 설정
                    state.dataTable.registeredUser.users = !!registeredUsers ? registeredUsers : new Array();

                    // 미등록 사용자
                    groupUserManage.getGroupUnregisteredUsers(
                        state.dataTable.group.selectedGroup.CodeVal
                    ).then(unregisteredUsers => {

                        // 미등록 사용자 목록 설정
                        state.dataTable.unregisteredUser.users = !!unregisteredUsers ? unregisteredUsers : new Array();
                    }).finally(() => store.commit("setLoadingStatus", false));
                }).catch(() => store.commit("setLoadingStatus", false));
            },

            // 미등록 사용자 목록 가져오기
            getGroupUnregisteredUsers: () => {
                // 스토어 값 변경
                store.commit('setLoadingStatus', true);

                groupUserManage.getGroupUnregisteredUsers(
                    state.dataTable.group.selectedGroup.CodeVal,
                    state.dropdown.userAdType.selectedItem,
                    state.searchKeyword,
                ).then(unregisteredUsers => {

                    // 미등록 사용자 목록 설정
                    state.dataTable.unregisteredUser.users = !!unregisteredUsers ? unregisteredUsers : new Array();
                }).finally(store.commit('setLoadingStatus', false));
            }
        }

        // 이벤트 핸들러
        const eventHandler = {

            // 데이터 테이블 관련
            dataTable: {

                // 그룹
                group: {

                    // 행 클릭 이벤트 처리
                    onRowClick: event => { },
                },
            },

            // 버튼 관련
            button: {

                // 저장
                save: {

                    // 클릭 이벤트 처리
                    onClick: () => {

                        const registerUsers = state.dataTable.registeredUser.users.filter(user => user.isNew);
                        const unregisterUsers = state.dataTable.unregisteredUser.users.filter(user => user.isNew);

                        if (!!registerUsers.length || !!unregisterUsers.length) {

                            // 스토어 값 변경
                            store.commit('setLoadingStatus', true);

                            alertConfirmManager.confirm.save().then(() => {

                                groupUserManage.saveGroupUsers(
                                    state.dataTable.group.selectedGroup.CodeVal,
                                    registerUsers,
                                    unregisterUsers
                                ).then(() => {

                                    // 그룹 사용자 목록 재조회
                                    methods.getGroupUsers();

                                    // 저장완료 토스트 메시지 실행
                                    alertConfirmManager.toast.save();
                                }).finally(store.commit('setLoadingStatus', false));
                            });
                        }
                        else alertConfirmManager.alert.warning('저장할 사용자가 없습니다.');
                    },
                },

                // 사용자 등록
                registerUser: {

                    // 클릭 이벤트 처리
                    onClick: () => {

                        state.dataTable.unregisteredUser.selectedUsers.forEach(selectedUser => {

                            // 미등록 사용자 목록에서 선택 사용자 삭제
                            state.dataTable.unregisteredUser.users = state.dataTable.unregisteredUser.users.filter(registerUser => registerUser.UserID !== selectedUser.UserID)
                            
                            // 신규여부 값 설정
                            selectedUser.isNew = selectedUser.isNew ? false : true;

                            // 등록된 사용자 목록에 선택 사용자 추가
                            state.dataTable.registeredUser.users.push(selectedUser);
                        });

                        // 선택 사용자 초기화
                        state.dataTable.unregisteredUser.selectedUsers = new Array();
                    },
                },

                // 사용자 등록해제
                unregisterUser: {
                    
                    // 클릭 이벤트 처리
                    onClick: () => {

                        state.dataTable.registeredUser.selectedUsers.forEach(selectedUser => {

                            // 등록된 사용자 목록에서 선택 사용자 삭제
                            state.dataTable.registeredUser.users = state.dataTable.registeredUser.users.filter(registerUser => registerUser.UserID !== selectedUser.UserID)
                            
                            // 신규여부 값 설정
                            selectedUser.isNew = selectedUser.isNew ? false : true;

                            // 미등록 사용자 목록에 선택 사용자 추가
                            state.dataTable.unregisteredUser.users.push(selectedUser);
                        });

                        // 선택 사용자 초기화
                        state.dataTable.registeredUser.selectedUsers = new Array();
                    },
                },

                // 미등록 사용자 검색
                searchUnregisteredUser: {

                    // 클릭 이벤트 처리
                    onClick: () => {
                        if(!!state.dataTable.group.selectedGroup) {
                            
                            // 미등록 사용자 목록 가져오기
                            methods.getGroupUnregisteredUsers();
                        }
                    },
                },
            },
        }

        // 그룹 선택 감시자
        watch(() => state.dataTable.group.selectedGroup, () => {

            // 그룹에 등록된, 미등록 사용자 목록 가져오기
            methods.getGroupUsers();

            // 등록된 사용자 선택 객체 초기화
            state.dataTable.registeredUser.selectedUsers = new Array();
            
            // 미등록 사용자 선택 객체 초기화
            state.dataTable.unregisteredUser.selectedUsers = new Array();
        });

        // Dom 객체 그려진 이후
        onMounted(() => {

            // 그룹관리 초기조회
            methods.getGroups();
        });

        return {
            state,
            methods,
            eventHandler,
        }
    }
}
</script>