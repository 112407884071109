import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 그룹 사용자 관리 화면 관련 기능 클래스
 */
class GroupUserManage {

    /**
     * 생성자
     */
    constructor() { }

    /**
     * 그룹목록 가져오기
     * @returns 
     */
    getGroups() {

        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url)
            const request = new FoxBizRequest()
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz'
            request.methodId = 'SelectGroupList'
            request.parameters.ParentCode = 'ROOT';
            request.parameters.UseYn = 'Y';

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }
    
    /**
     * 그룹에 등록된 사용자 목록 가져오기
     * @param {string} groupCode 
     * @returns 
     */
    getGroupRegisteredUsers(groupCode) {

        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url)
            const request = new FoxBizRequest()
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz'
            request.methodId = 'SelectGroupUserList'
            request.parameters.ParentCode = groupCode;
            request.parameters.UseYn = 'Y';

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) {

                    // 신규여부 플래그 값 설정 (false로 초기화)
                    rows.forEach(row => row.isNew = false);
                    
                    // 결과 반환
                    resolve(rows);
                }
                else resolve(null);
            }).catch(error => reject(error));
        });
    }

    /**
     * 그룹에 미등록 사용자 목록 가져오기
     * @param {string} groupCode : 그룹코드
     * @param {string} userAdType : 사용자 AD 유형 
     * @param {string} searchKeyword : 검색어
     * @returns 
     */
    getGroupUnregisteredUsers(groupCode, userAdType = null, searchKeyword = null) {

        return new Promise((resolve, reject) => {
            
            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url)
            const request = new FoxBizRequest()
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz'
            request.methodId = 'SelectGroupUnUserList'
            request.parameters.ParentCode = groupCode;
            request.parameters.UseType = userAdType;
            request.parameters.SearchText = searchKeyword;
            request.parameters.UseYn = 'N';

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) {
                    
                    // 신규여부 플래그 값 추가 (false로 초기화)
                    rows.forEach(row => row.isNew = false);
                    
                    // 결과 반환
                    resolve(rows);
                }
                else resolve(null);
            }).catch(error => reject(error));
        });
    }

    /**
     * 그룹 사용자 저장
     * @param {string} groupCode : 그룹코드
     * @param {array} registerUsers : 등록할 사용자 목록
     * @param {array} unregisterUsers : 등록해제할 사용자
     * @returns 
     */
    saveGroupUsers(groupCode, registerUsers = [], unregisterUsers = []) {

        return new Promise((resolve, reject) => {
            
            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url)
            const request = new FoxBizRequest()
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz'
            request.methodId = 'InsertGroupUserSave'
            request.parameters.parentCode = groupCode;
            request.parameters.selectedItemsAll = JSON.stringify(registerUsers);
            request.parameters.unSelectedItemsAll = JSON.stringify(unregisterUsers);

            service.execute(request).then(response => {

                const resultCount = response.data.result.value;

                // 결과 반환
                resolve(resultCount);
            }).catch(error => reject(error));
        });
    }
}

export const groupUserManage = new GroupUserManage();